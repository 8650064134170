.copyright {
  width: 100%;
  text-align: center;
  background-color: black;
  padding: 2rem 0;
  position: relative;
}

.copyright p {
  margin-top: 10px;
  color: white;
  font-size: 1.4rem;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(253, 87, 191, 0.99);
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(253, 87, 191, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(253, 87, 191, 0);
  }
}

/* ============================
========footer icons========
============================ */
.icon-list {
  /* border: solid red 2pt; */
  /* max-width: 98rem; */
  width: 70%;
  padding: 0 1.5rem;
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  margin: -30px auto;
}

.icon-item {
  list-style: none;
}

.footer-icon-link {
  display: flex;
  font-size: 20px;
  text-decoration: none;
  color: #acaeb3ea;
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.1),
    -0.3rem -0.3rem 2rem rgba(255, 255, 255, 0.5);
}

.footer-icon-link i {
  margin: auto;
  text-decoration: none;
}

.footer-icon-link:focus {
  box-shadow: inset 1rem 1rem 2rem rgba(0, 0, 0, 0.1),
    inset -1rem -1rem 2rem rgba(255, 255, 255, 0.5);
  text-decoration: none;
}

/* ============================
========media queries========
============================ */
@media screen and (max-width: 800px) {
  .copyright {
    margin-top: -20px;
  }
}
