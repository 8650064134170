.form-section {
  background-color: black;
  margin: 0 auto;
  margin-bottom: -50px;
  /* padding-top: 150px; */
}

.section-heading {
  text-align: center;
  color: white;
}

.form-container {
  color: white;
  margin: 70px auto 0px;
  width: 70%;
}

.form-container label {
  display: block;
  letter-spacing: 0.1rem;
  font-style: italic;
  margin-bottom: 3px;
}

.form-container input,
.form-container textarea {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  border: none;
  box-shadow: 1px 1px 4px 1px gray;
}

/* .form-container input:focus,
.form-container textarea:focus {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
} */

.singleitem {
  margin: 10px 0;
}

.form-btn {
  width: 100%;
  text-align: center;
}

.form-btn button {
  width: 150px;
  font-size: 14px;
  letter-spacing: 0.2rem;
  padding: 15px 10px;
  border: 1px solid transparent;
  outline: none;
  transition: all 0.5s ease-in-out;
  position: relative;
  margin-top: 15px;
  margin-bottom: -200px;
  /* background-color: red; */
}

.msg {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 10px;
  letter-spacing: 0.2rem;
  font-style: italic;
  width: 340px;
  padding: 3px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(5, 110, 5);
  background-color: #fff;
  border-radius: 5px;
  display: none;
}

.msgAppear {
  display: block;
}

@media (max-width: 800px) and (-webkit-device-pixel-ratio: 3) {
  .form-section {
    /* background-color: black;
    margin: auto; */
    padding-top: 50px;
  }

  .form-btn button {
    /* margin-bottom: 50px; */

    background-color: #ff69b4;
    background-color: linear-gradient(to top, #ff69b4, #c32c7f, #88004e);
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3),
  only screen and (min-resolution: 458dpi),
  only screen and (min-resolution: 3dppx) {
  /* Retina styles here */
}
