.timeline-heading {
  text-align: center;
  font-weight: 300;
  color: #777;
}

.timeline-heading span {
  font-weight: 600;
}

.experience-container {
  width: 80%;
  padding: 50px 0;
  margin: 50px auto;
  position: relative;
  overflow: hidden;
}

.experience-container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 100%;
  background: #ccd1d9;
  z-index: 1;
}

.timeline-block {
  width: -webkit-calc(50% + 8px);
  width: -moz-calc(50% + 8px);
  width: calc(50% + 8px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #f5f7fa;
  background: linear-gradient(to top, #ff69b4, #c32c7f, #88004e);
  margin-top: 10px;
  z-index: 9999;
}

.timeline-content {
  width: 95%;
  padding: 0 15px;
  color: #666;
  background-color: whitesmoke;
}

.timeline-content h3 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: 500;
}

.timeline-content span {
  font-size: 15px;
  color: #a4a4a4;
  text-transform: uppercase;
}

.timeline-content p {
  font-size: 14px;
  line-height: 1.5em;
  word-spacing: 1px;
  color: #888;
  text-transform: lowercase;
  /* color: #c32c7f; */
}

.timeline-description-paragraph {
  text-transform: lowercase;
  /* color: red; */
}

.timeline h1 {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 1rem;
  opacity: 0.3;
}

.timeline ul .date p {
  color: black;
}

.timeline .date {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  color: black;
  position: relative;
}

.timeline .date:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.timeline .date::before,
.timeline .date::after {
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
}

.timeline .date::before {
  content: attr(data-date);
  left: -21rem;
  text-align: right;
  min-width: 12rem;
  font-size: 1.5rem;
}

.timeline .date::after {
  content: "";
  box-shadow: 0 0 0 4px #fd57bf;
  border-radius: 100%;
  left: -8rem;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #313534;
}

.experience-btn {
  font-size: 12px;
  /* display: block; */
  display: flex;
  width: fit-content;
  margin: -80px auto 40px;
  padding: 20px;
}

.experience-btn a {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 768px) {
  .timeline-content {
    width: 100%;
    /* padding: 0 15px;
    color: #666;
    background-color: whitesmoke; */
  }

  .experience-container:before {
    left: 8px;
    width: 2px;
  }

  .timeline-content p {
    padding-top: 10px;
  }

  .timeline-block {
    width: 100%;
    margin-bottom: 30px;
  }
  .timeline-block-right {
    float: none;
  }

  .timeline-block-left {
    float: none;
    direction: ltr;
  }
}
