/* =====================================================
  =========Menu Items component=========================
  ===================================================== */

.nav-list {
  background: black;

  height: 100vh;
  transition: "height 0.3s ease";
  z-index: 2;
  position: fixed;
  transition: all 650ms cubic-bezier(1, 0, 0, 1);
  /* new */
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-links {
  display: flex;
  list-style: none;
  height: 100%;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin: auto;
}

li {
  margin: 0 2rem;
}

.nav-links li a {
  /* font-family: "Roboto", "Raleway"; */
  color: white;
  text-decoration: none;
  font-size: 1.5vw;
  font-weight: 900;
  line-height: 8vh;

  /* padding: 1rem; */
}

.nav-links li:hover,
.nav-links li:focus {
  background: linear-gradient(to top, #ff69b4, #c32c7f, #88004e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* =====================================================
=========Menu Button Component================================
===================================================== */
.burgermenu-lines-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  position: absolute;
  top: 3rem;
  right: 5rem;
  width: 7rem;
  height: 5rem;
  transition: transform 650ms ease-out;
  cursor: pointer;
  z-index: 1500;
  /* justify-content: space-between; */
  /* background: black; */
}

.linetop,
.linemiddle,
.linebottom {
  background: linear-gradient(to top, #ff69b4, #c32c7f, #88004e);
  width: 100%;
  height: 7px;
  border-radius: 0.8rem;
  transition: all 0.2s ease;
}

.linetop {
  position: absolute;
  top: 0;
  right: 2.9rem;
  width: 60%;
  transform-origin: -10% 150%;
  transition: transform 650ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
}

.linemiddle {
  position: absolute;
  top: 2rem;
  transform-origin: 55% 390%;
  transition: transform 650ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
}

.linebottom {
  position: absolute;
  right: -0.2rem;
  bottom: 0rem;
  width: 60%;
  transform-origin: 30% -425%;
  transition: transform 650ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
}

@media screen and (max-width: 800px) {
  /* =====================================================
  =========Menu Button component=========
  ===================================================== */
  .burgermenu-lines-container {
    padding: 0;
    height: 3rem;
    /* border: blue solid 3pt; */
  }

  .linetop,
  .linemiddle,
  .linebottom {
    background: black;
    background: linear-gradient(to top, #ff69b4, #c32c7f, #88004e);
    width: 60%;
    left: 70px;
  }

  .linemiddle {
    top: 1.2rem;
    /* transform-origin: 55% 390%;
    transition: transform 650ms cubic-bezier(0.54, -0.81, 0.57, 0.57); */
  }

  .linebottom {
    /* transform-origin: 920% 690%;
    transition: transform 650ms cubic-bezier(0.57, -0.81, 0.57, 0.57); */
    visibility: hidden;
  }

  /* =====================================================
  =========Menu Items component=========================
  ===================================================== */

  .nav-links {
    height: 100%;
    width: 100%;
    flex-direction: column;
    clip-path: circle(1000px at 90% -10%);
    -webkit-clip-path: circle(1000px at 90% -10%);
    transition: all is ease-out;
  }
  .nav-links.open {
    clip-path: circle(1000px at 90% -10%);
    -webkit-clip-path: circle(1000px at 90% -10%);
    pointer-events: all;
  }

  .nav-links li a {
    font-size: 20px;
  }

  .nav-links {
    margin: auto;
    margin-top: 50px;
  }

  .nav-list {
    height: 50rem;
    margin-top: -30px;
    /* transition: "height 0.3s ease"; */
    /* z-index: 2; */
    /* position: fixed; */
    /* transition: all 650ms cubic-bezier(1, 0, 0, 1); */
    /* new */
    /* list-style: none; */
    /* width: 100%; */
    /* height: 100%; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
  }
}
