.my-work {
  background-color: var(--clr-dark);

  color: var(--clr-light);
  text-align: center;
}

.my-work .section__title {
  font-size: 7vw;
  opacity: 0.3;
}

.my-work .section__subtitle {
  font-size: 1.5vw;
  margin: -10px 0 20px;
}

.portfolio {
  display: grid;
  grid-template-columns: 1fr;
}

.portfolio-item {
  display: grid;
  grid-template-rows: auto;
  grid-template-areas:
    "heading  heading heading heading heading heading"
    "img      img     img     desc    desc    desc"
    "img      img     img     desc    desc    desc"
    "img      img     img     tech    tech    tech"
    "img      img     img     dep     dep     dep"
    "img      img     img     links   links   links";
  background: var(--clr-accent);
  background-image: url("https://i.imgur.com/94KvXLY.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 45px;
}

/* =====================================
====Portfolio Item Title ======== =======
======================================== */

.portfolio-item-title {
  grid-area: heading;
  font-size: 4rem;
  margin: 15px 0 50px;
  color: #333;
}

/* ===================================================
===Portfolio description highlight and paragraph======
=================================================== */

.portfolio-item .portfolio-description {
  grid-area: desc;
  width: 50vw;
}

.portfolio-item-tagline {
  color: #333;
  font-size: 2.3rem;
  width: 85%;
  margin: auto;
}

.portfolio-item-longdescription {
  color: #333;
  margin-top: 20px;
  text-align: justify;
  width: 85%;
  margin: 30px auto;
  font-size: 1.3rem;
}

/* =====================================
====Live site and see code links =======
======================================== */

.portfolio-links {
  grid-area: links;
}

.portfolio-links .btn {
  margin: 0 20px 40px;
}

/* ===============================
====Portfolio Tech Used =======
=============================== */

.portfolio-techused {
  grid-area: tech;
  width: 60%;
  margin: auto;
  color: #333;
}

.portfolio-techused h6 {
  /* grid-area: heading; */
  width: 100%;
  font-size: 2rem;
  text-decoration: underline;
}

.techused-details {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 20px;
  font-size: 1.5rem;
}

/* ===============================
====Portfolio dependancies=====
=============================== */
.portfolio-dependencies {
  /* border: solid 2pt red; */
  grid-area: dep;
  width: 60%;
  margin: auto;
  color: #333;
}

.portfolio-dependencies h6 {
  /* grid-area: heading; */
  width: 100%;
  font-size: 2rem;
  text-decoration: underline;
  text-transform: uppercase;
}

.dependencies-details {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 20px;
  font-size: 1.5rem;
}
/* ===============================
====Portfolio wireframes =========
=============================== */

.porfolioitem-image-container {
  grid-area: img;
  max-height: 700px;
  overflow: scroll;
  border-radius: 2px;
  box-shadow: 0 0 16px #333;
  transition: all 1.5s ease;
  margin-bottom: 30px;
}

.porfolioitem-image-container::-webkit-scrollbar {
  -webkit-appearance: none;
}

.porfolioitem-image-container::-webkit-scrollbar:vertical {
  width: 11px;
}

.porfolioitem-image-container::-webkit-scrollbar:horizontal {
  height: 11px;
}

.porfolioitem-image-container::-webkit-scrollbar-thumb {
  /* border-radius: 8px; */
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
}

/* =======================================
===========Media Queries===============
======================================= */

@media (max-width: 800px) {
  .portfolio {
    margin-left: -15px;
  }
  .my-work .section__title {
    font-size: 15vw;
    margin-top: -30px;
  }

  .my-work .section__subtitle {
    font-size: 3.5vw;
    margin: -5px 0 20px;
  }

  .portfolio-item-title {
    font-size: 4.5vw;
    margin-bottom: 10px;
  }

  .portfolio-item {
    grid-template-areas:
      "heading  heading heading heading heading heading"
      "img      img     img     desc    desc    desc"
      "tech    tech    tech     dep     dep     dep"
      "links   links   links     links   links   links";
  }

  .portfolio-description {
    /* border: solid green 2pt; */
    /* font-size: 8.3vw; */
    height: fit-content;
  }

  /* .portfolio-links {
    grid-area: links;
  } */

  .portfolio-links .btn {
    margin: 10px 20px 20px;
    width: 120px;
    font-size: 8px;
  }

  .portfolio-item-tagline {
    font-size: 3vw;
  }

  .portfolio-item-longdescription {
    font-size: 2.6vw;
    /* border: solid blue 2pt; */
    max-height: 250px;
    margin-top: 10px;
    width: 90%;
    /* margin-bottom: -40px; */
  }

  .portfolio-techused,
  .portfolio-dependencies {
    /* border: solid red 2pt; */
    display: block;
    width: 150px;
    margin-left: 20px;
    /* display: flex; */
    height: 90px;
    margin-top: -20px;
  }

  .portfolio-techused h6,
  .portfolio-dependencies h6 {
    font-size: 3vw;
    /* width: 150px; */
    margin-top: 20px;
  }

  .dependencies-details,
  .techused-details {
    font-size: 2.4vw;
  }

  .porfolioitem-image-container {
    /* grid-area: img; */
    height: 300px;
    /* overflow: scroll;
    border-radius: 2px;
    box-shadow: 0 0 16px #333;
    transition: all 1.5s ease;
    margin-bottom: 30px; */
  }
}
