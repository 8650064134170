.scroll-up {
  /* width: 100%; */
  /* text-align: center; */
  position: relative;
  background-color: black;
  padding: 2rem 0;
}

.up {
  /* position: absolute; */
  width: 5rem;
  height: 5rem;
  background-color: #eee;
  border-radius: 100%;
  display: flex;
  animation: pulse 2s infinite;
  cursor: pointer;
  /* ///////////////////////// */
  position: fixed;
  z-index: 2;
  right: 6rem;
  bottom: 5rem;
}

.up i {
  /* color: #16162d; */
  font-size: 2rem;
  margin: auto;
}

/* .up:hover {
  transition: 0.3s;
} */

@media screen and (max-width: 800px) {
  /* .up {
    position: absolute;
  
   
  } */

  .scroll-up {
    text-align: center;
  }

  .up {
    position: absolute;
    width: 5rem;
    height: 5rem;
    background-color: #eee;
    top: -1rem;
    right: -8rem;
    border-radius: 100%;
    display: flex;
    animation: pulse 2s infinite;
    cursor: pointer;
    scroll-behavior: smooth;
  }

  .up i {
    color: #16162d;
    font-size: 2rem;
    margin: auto;
  }
}
