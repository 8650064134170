.intro {
  display: grid;
  width: min-content;
  margin: 0 auto;
  grid-column-gap: 1em;
  grid-template-areas:
    "img title"
    "img subtitle";
  grid-template-columns: min-content max-content;
}

.intro__img {
  grid-area: img;
  min-width: 300px;
  /* position: relative; */
}

.section__title {
  margin-bottom: 0.25em;
}

.section__subtitle--intro {
  align-self: start;
  grid-column: -1 / 1;
  grid-row: 2;
  text-align: right;
  background-color: black;
  color: white;
  font-size: 2rem;
  font-weight: var(--fw-reg);
}

.section__title--intro strong {
  display: block;
}

.section__subtitle--intro,
.section__subtitle--about {
  padding: 0.25em 1em;
  font-family: var(--ff-secondary);
  margin-bottom: 1em;
}

.nav__link {
  color: inherit;
  font-weight: var(--fw-bold);
  font-size: 10pt;
  text-decoration: none;
  padding: 0 2.5vw;
}

/* ===================================
===================================
==========New Code=================
===================================
=================================== */

.border {
  box-sizing: border-box;
  position: relative;
  background: black;
  background-image: linear-gradient(top right, #5d5d5b, #383838);
  padding: 7px;
  width: 220px;
  box-shadow: -1px 1px var(--blur) 1px rgba(0, 0, 0, 0.1),
    -2px 2px var(--blur) 1px rgba(0, 0, 0, 0.09),
    -3px 3px var(--blur) 1px rgba(0, 0, 0, 0.08),
    -4px 4px var(--blur) 1px rgba(0, 0, 0, 0.07),
    -5px 5px var(--blur) 1px rgba(0, 0, 0, 0.06),
    -6px 6px var(--blur) 1px rgba(0, 0, 0, 0.05),
    -7px 7px var(--blur) 1px rgba(0, 0, 0, 0.04),
    -8px 8px var(--blur) 1px rgba(0, 0, 0, 0.03),
    -9px 9px var(--blur) 1px rgba(0, 0, 0, 0.03),
    -10px 10px var(--blur) 1px rgba(0, 0, 0, 0.03),
    -11px 11px var(--blur) 1px rgba(0, 0, 0, 0.03),
    -12px 12px var(--blur) 1px rgba(0, 0, 0, 0.02),
    -13px 13px var(--blur) 1px rgba(0, 0, 0, 0.02),
    -14px 14px var(--blur) 1px rgba(0, 0, 0, 0.01),
    -15px 15px var(--blur) 1px rgba(0, 0, 0, 0.01),
    -16px 16px var(--blur) 1px rgba(0, 0, 0, 0.01);
}
.border:before {
  content: " ";
  display: block;
  padding-bottom: 140%;
}

.frame {
  left: 3%;
  top: 2.5%;
  box-shadow: inset -1px 1px 6px 1px rgba(0, 0, 0, 0.24);
  width: 94%;
  height: 95%;
  background: white;
  align-items: center;
  display: flex;
  padding: 18px;
  box-sizing: border-box;
  position: absolute;
}

/* .image {
  box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
} */

@media screen and (max-width: 800px) {
  .intro {
    /* position: relative; */
    /* border: solid red 2pt; */
    width: 95%;
    margin-top: 30px;
    grid-template-areas:
      "title"
      "subtitle"
      "img";
    grid-template-columns: 1fr;
  }

  .intro__img {
    grid-area: img;
    min-width: 65vw;
    position: relative;
    width: 100%;
    /* margin: auto; */
  }

  .section__title--intro strong {
    /* font-size: 40px; */
  }

  .section__subtitle--intro {
    max-width: fit-content;
    /* max-width: 60vw; */

    font-weight: 500;
    display: inline-block;
    font-size: 18px;
    text-align: left;
  }

  .image {
    height: 100%;
    padding-top: 10px;

    margin: auto;
  }
}
