/* .my-specializations {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://i.imgur.com/E2YBaaQ.jpg");
  opacity: 0.9;
}

.specializations-highlight {
  width: 85vw;
  background-color: whitesmoke;
  opacity: 0.92;
  margin: auto;
  border-style: ridge;
  border-width: 3px;
  padding: 1em;
} */

/* ====================================
====section title=====================
===================================== */
/* .section__title--services {
  text-align: center;
  font-size: 48px;
  text-transform: uppercase;
  padding: 25px 0;
} */

/* ===========================================================
==== grid area service tilte and paragraph=====================
============================================================ */
/* .services {
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr;
  grid-auto-flow: column;
  border: #88004e 1pt solid;
}

.services h3 {
  background: linear-gradient(to top, #ff69b4, #c32c7f, #88004e);
  padding: 20px;
  font-size: 26px;
  border: #88004e 1pt solid;
  text-align: center;
  align-items: center;
 
  display: block;
} */

/* ====================================
====paragraph outside expanded view=======
===================================== */

/* ====================================
====paragraph inside expanded view=======
===================================== */
/* .service {
  border-left: #88004e 1pt solid;
  border-right: #88004e 1pt solid;
  border-bottom: #88004e 1pt solid;
  padding-bottom: 20px;

  font-size: 1vw;
  text-align: justify;
  padding: 10px;
 
}

.service p {
  padding: 10px;
} */

/* ====================================
====read more/less link=====================
===================================== */

/* .service-paragraph {
  color: #88004e;
  padding-left: 10px;
} */

/* ====================================
====My Work button=====================
===================================== */

/* .specializations-highlight .btn {
  background-color: #c32c7f;
  color: whitesmoke;
  margin-bottom: 40px;
  margin-top: 25px;
  padding: 10px 20px;
  display: block;
  width: 150px;
  margin: 25px auto 10px;
}

.specializations-highlight a {
  align-items: center;
  color: black;
} */

/* ====================================
====Media QUery=====================
===================================== */

/* @media (max-width: 868px) {
  .my-specializations {
    margin-top: -90px;
  }

  .services {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }

  .section__title--services {
    font-size: 6.2vw;
    color: black;
  }

  .service p {
    font-size: 10px;
  }

  .service h3 {
    color: black;
    font-size: 3.8vw;
  }
} */

/* ////////////////////////////////
////////////New Code////////////
/////////////////////////////// */

.my-specializations {
  /* background-color: var(--clr-dark); */
  /* background-color: #ff69b4;
  background-color: #c32c7f;*/
  /* background-image: url("https://i.imgur.com/EptIkkq.jpg"); */
  /* background-color: url("https://i.imgur.com/vsc3kJR.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://i.imgur.com/E2YBaaQ.jpg");
  text-align: center;
  opacity: 0.9;
}

.specializations-highlight {
  /* background: linear-gradient(to top, #ff69b4, #c32c7f, #88004e); */
  width: 85vw;
  background-color: whitesmoke;
  opacity: 0.92;
  margin: auto;
  border-style: ridge;
  border-width: 3px;
  padding: 1em;
}

/* ====================================
====section title=====================
===================================== */

.section__title--services {
  /* color: var(--clr-dark); */
  /* color: #c32c7f; */
  font-size: 3.7vw;
  text-transform: uppercase;
  padding: 25px 0;
}

.section__title--services::after {
  /* content: ""; */
  display: block;
  margin: 0.5em auto 1em;
}
/* ===========================================================
==== grid area service tilte and paragraph=====================
============================================================ */

.services {
  /* border: red 9pt solid; */
  /* grid-gap: 20pt; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  margin: auto;
}

.service h3 {
  /* border: pink 2pt solid; */
  background: linear-gradient(to top, #ff69b4, #c32c7f, #88004e);
  padding: 20px 0;
  margin-bottom: 20px;
  /* text-decoration: underline; */
  /* color: white; */
  font-size: 1.8vw;
}

/* ====================================
====paragraph description=======
===================================== */
.service {
  /* width: 95%; */
  /* margin: 0 auto; */
  border: #88004e 2pt solid;
  /* border: yellow 2pt solid; */
  /* font-size: 1.1vw; */
}

.service p {
  /* background-color: aqua; */
  padding: 0 10px;
  /* padding: 15px; */
}

/* ====================================
====read more/less link=====================
===================================== */

.service-paragraph {
  padding: 0 20px;
  color: #88004e;
  padding-left: 20px;
}

/* ====================================
====My Work button=====================
===================================== */

.specializations-highlight .btn {
  background-color: #c32c7f;
  color: whitesmoke;
  margin-bottom: 40px;
  margin-top: 25px;
  padding: 10px 20px;
}

.specializations-highlight .btn {
  color: black;
}

/* ====================================
====Media QUery=====================
===================================== */

@media (max-width: 868px) {
  .my-specializations {
    margin-top: -90px;
  }

  .services {
    /* display: flex; */
    /* flex-direction: column; */
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0;
  }

  .section__title--services {
    font-size: 6.2vw;
    color: black;
  }

  .service {
    /* width: 95%; */
    /* margin: 0 auto; */
    /* border: #88004e 2pt solid; */
    /* border: yellow 2pt solid; */
    /* font-size: 3vw; */
    /* margin-top: 120px; */
    padding-bottom: 20px;
  }

  .service h3 {
    color: black;
    font-size: 3.8vw;
  }
}
